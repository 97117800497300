import * as PrismicTypes from '@prismicio/types'

import NextHeader from '../components/NextHeader'
import PageSliceZone from '../components/SliceZone/PageSliceZone'
import { IFrontPage } from '../types/prismic/pages/frontPage'
import { INews } from '../types/prismic/pages/news'
import { Language } from '../types/Types'
import { getSingleByType } from '../utils/prismic/data-helpers'
import { getDefaultPageData } from '../utils/prismic/RouteHelpers'
import { getPageSEOData } from '../utils/seoHelpers'
import { getData, changeLastRef } from '../store'

interface IProps {
	frontpage: IFrontPage.IProps
	newsArticleSummary: INews.IArticle[]
	preview: boolean
	previewData: PrismicTypes.Ref
	locale: Language
}

const Index: React.FC<IProps> = ({ frontpage, previewData }) => {
	return (
		<>
			<NextHeader {...getPageSEOData(frontpage)} />
			<PageSliceZone slices={frontpage.data.body} />
		</>
	)
}

export const getStaticProps = async ({
	previewData,
	locale,
	...other
}: IProps) => {
	let { ref } = previewData || {}
	const storedData = getData()
	if ((!ref || ref.length === 0) && storedData.lastRef.length > 0)
		ref = storedData.lastRef

	const deletedNews = storedData.deletedNews

	const frontpage = await getSingleByType<IFrontPage.IProps>(
		'frontpage_v2',
		ref,
		locale,
		deletedNews
	)

	return {
		props: {
			frontpage,
			...(await getDefaultPageData(ref, locale)),
			pageId: frontpage.id,
		},
	}
}
export default Index
